import React, { useState, useEffect } from "react"
import Recaptcha from "react-google-recaptcha"
import { RefreshContent } from "react-js-pull-to-refresh"
import { isAuthenticated, getEmail } from "../../utils/Auth"
// Material UI Components
import { Input } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
// Material UI Kit
import CustomInput from "../CustomInput/CustomInput"
import Button from "../CustomButtons/Button"
// Styling
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

const InquiryForm = props => {
  const { classes, success } = props
  const [state, setState] = useState({ email: "" })
  const [disabled, setDisabled] = useState(false)
  const recaptchaRef = React.createRef()

  const baseStates = () => {
    const type = { customerType: "Finance" }
    let message = {message: ""}
    props.url !== undefined &&
      (message = { message: `${props.title} - ${props.url}` })
    let email = {email: ""}
      isAuthenticated() && (email = {email: getEmail()})

    setState({ ...state, ...type, ...message, ...email })
  }

  useEffect(() => {
    baseStates()
  }, [true])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = e => {
    e.preventDefault()
    setDisabled(true)
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch("/.netlify/functions/agile", {
      method: "POST",
      body: JSON.stringify({
        recaptchaToken: recaptchaValue,
        amount: props.deal.amountValue,
        down: props.deal.downPaymentValue,
        term: props.deal.yearsValue,
        ...state,
      }),
    })
      .then(response => {
        // console.log(response)
        setDisabled(false)
        setTimeout(() => success(), 2000)
      })
      .catch(error => console.log(error))
  }

  const menuItems = names => {
    const { classes } = props
    return names.map(v => {
      return (
        <MenuItem
          key={v.id}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={v.id}
        >
          {v.name}
        </MenuItem>
      )
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <CustomInput
        labelText="First Name"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
          required: true,
        }}
        inputProps={{
          onChange: handleChange,
          type: "text",
          className: "form-control",
          name: "firstName",
          placeholder: "First Name...",
          autoFocus: false,
        }}
      />
      <CustomInput
        labelText="Last Name"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
          required: true,
        }}
        inputProps={{
          onChange: handleChange,
          type: "text",
          className: "form-control",
          name: "lastName",
          placeholder: "Last Name...",
          autoFocus: false,
        }}
      />
      <CustomInput
        labelText="Email"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
          required: true,
        }}
        inputProps={{
          onChange: handleChange,
          name: "email",
          type: "text",
          className: "form-control",
          placeholder: "Email...",
          autoFocus: false,
          value: state["email"],
        }}
      />
      <CustomInput
        labelText="Phone Number"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
          required: true,
        }}
        inputProps={{
          onChange: handleChange,
          name: "phone",
          type: "tel",
          className: "form-control",
          placeholder: "Phone Number...",
          autoFocus: false,
        }}
      />
      <FormControl
        required
        fullWidth
        className={classes.selectFormControl}
        style={{ padding: "13px 0" }}
      >
        <InputLabel htmlFor={"customerType"} className={classes.selectLabel}>
          {"I am..."}
        </InputLabel>
        <Select
          multiple={false}
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={
            state["customerType"] !== undefined
              ? state["customerType"]
              : "Finance"
          }
          onChange={handleChange}
          inputProps={{
            name: "customerType",
            id: "customerType",
          }}
        >
          {menuItems([
            { id: "Finance", name: "Buying a new/previously owned boat" },
            { id: "Refinance", name: "Refinancing/Repowering existing boat" },
            { id: "Dealer", name: "A Dealer interested for a customer" },
          ])}
        </Select>
      </FormControl>
      {state["customerType"] === "Refinance" && (
        <CustomInput
          labelText="Amount Owed on Current Loan (if any)"
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses,
          }}
          inputProps={{
            onChange: handleChange,
            name: "amountOwed",
            type: "text",
            className: "form-control",
            placeholder:
              "Total Amount Owed on Current Loan if any (Approximate is Ok)...",
            autoFocus: false,
          }}
        />
      )}

      {props.url === undefined && (
        <Input
          label="Message"
          type="text"
          name="message"
          onChange={handleChange}
          placeholder={
            "Please provide the Year, Make and Model of the boat as well as any other details that may help the process (i.e. Number of Engine(s), Engine(s) Year (if different), Engine Hours, Features, etc)"
          }
          style={{ width: "100%" }}
          required={true}
          inputProps={{ maxLength: 1000 }}
          multiline={true}
          rows={6}
        />
      )}
      <Recaptcha
        ref={recaptchaRef}
        sitekey={RECAPTCHA_KEY}
        style={{ textAlign: "-webkit-center", margin: "20px" }}
        size={"compact"}
      />
      {disabled && <RefreshContent />}
      {!disabled && (
        <Button
          disabled={disabled}
          type="submit"
          color="primary"
          style={{ float: "right" }}
        >
          Send Inquiry
        </Button>
      )}
    </form>
  )
}

export default withStyles(javascriptStyles)(InquiryForm)
