import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// core components
import GridContainer from "../../Grid/GridContainer.jsx"
import GridItem from "../../Grid/GridItem.jsx"
import Card from "../../Card/Card"
import CardBody from "../../Card/CardBody"
// styles
import productStyle from "../../../assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx"
// logos
import boatDepot from "../../../assets/img/boatdepotlogo.png"
import stonerBoatworks from "../../../assets/img/stonerboatworkslogo.png"
import grady from "../../../assets/img/qrady_canyon_306.jpg"

class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Let's talk service</h2>
            <h5 className={classes.description}>
              While exploring new opportunities, particularly in areas of
              perceived deficiency, we always ask "what can we do to make this
              better?" In the case of finance we knew that our service would be
              a major factor in the difference along with the phenomenal
              products we offer for both buyers and sellers. Whether a private
              seller, a dealer or a buyer, we promise to go above and beyond to
              make the deals as simple and quick for all parties involved.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card style={{ width: "20rem" }}>
                <img
                  style={{
                    height: "180px",
                    width: "100%",
                    display: "block",
                    padding: "10px",
                  }}
                  className={classes.imgCardTop}
                  src={boatDepot}
                  alt="Card-img-cap"
                />
                <CardBody style={{ height: "258px" }}>
                  <p>
                    Center Consoles Only allows us to market our boats
                    professionally to a large audience that helps us generate a
                    large amount of serious leads. Along with the serious
                    marketing tool, they are now assisting our customers in
                    finding the best boat loans suitable for them.
                  </p>
                  <p style={{ textAlign: "right", fontWeight: "600" }}>
                    Richard Garcia
                    <br />
                    Owner - Boat Depot <br />
                    Key Largo, FL
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card style={{ width: "20rem" }}>
                <img
                  style={{ height: "180px", width: "100%", display: "block" }}
                  className={classes.imgCardTop}
                  src={stonerBoatworks}
                  alt="Card-img-cap"
                />
                <CardBody style={{ height: "258px" }}>
                  <p>
                    We built a boat for a customer who was quoted a 12% loan
                    from several banks that he could not accept. After working
                    with him for over a year, we reached out to CCO Finance
                    and within a few days they had a 4.99% loan secured with
                    close to no effort from me. They were great from start to
                    finish!
                  </p>
                  <p style={{ textAlign: "right", fontWeight: "600" }}>
                    Joshua Stoner
                    <br />
                    Owner - Stoner Boatworks
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card style={{ width: "20rem" }}>
                <img
                  style={{ height: "180px", width: "100%", display: "block" }}
                  className={classes.imgCardTop}
                  src={grady}
                  alt="Card-img-cap"
                />
                <CardBody style={{ height: "258px" }}>
                  <p>
                    I would like to express my satisfaction with CCO
                    Finance, especially Jimmy Velez. The process to buy my
                    boat was really fast and easy. I am really thankful for the
                    attention I had from him. He was really kind and I will
                    recommend his services for sure. Thank you!
                  </p>
                  <p style={{ textAlign: "right", fontWeight: "600" }}>
                    Orlando Marrero
                    <br />
                    New Owner <br />Grady White Canyon 306
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    )
  }
}

export default withStyles(productStyle)(SectionProduct)
