import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
// Material UI Kit
// Styles
import withStyles from "@material-ui/core/styles/withStyles"
import javascriptStyles from "../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles"
import FinancingPage from "../components/Finance";

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
    alignItems: "center",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
  message: {
    // padding: "40px 90px",
    color: "#626f78",
  },
}

const FinancePage = props => {
  const { classes } = props
  return (
    <Layout maxWidth={"xl"} disableGutters={true}>
      <SEO title="CCO Finance" />
      <FinancingPage />
    </Layout>
  )
}
export default withStyles(javascriptStyles)(FinancePage)
