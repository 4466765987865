import React from "react"
import { isBrowser } from "../../utils/Auth"
import { EmbedVideo } from "./EmbedVideo"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import styles from "../../assets/jss/material-kit-pro-react/components/parallaxStyle.jsx"
// Intro Video
const introVideo = "https://res.cloudinary.com/cco/video/upload/vc_auto,e_volume:mute/v1594406820/defaults/intro_v2"

const useStyles = makeStyles(styles)

export default function Parallax(props) {
  if (!isBrowser) return null

  let windowScrollTop
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3
  } else {
    windowScrollTop = 0
  }

  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  )
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform, {passive: true})
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform)
      }
    }
  })
  const resetTransform = () => {
    const windowScrollTop = window.pageYOffset / 3
    setTransform("translate3d(0," + windowScrollTop + "px,0)")
  }
  const {
    filter,
    className,
    children,
    style,
    image,
    small,
    intro,
    isMobile,
    doTransform = true,
  } = props
  const classes = useStyles()
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes[filter + "Color"]]: filter !== undefined,
    [classes.small]: small,
    [className]: className !== undefined,
  })
  return (
    <>
      {!intro ? (
        <div
          className={parallaxClasses}
          style={{
            ...style,
            backgroundImage: "url(" + image + ")",
            transform: doTransform && transform,
          }}
        >
          {children}
        </div>
      ) : (
        <div
          className={parallaxClasses}
          style={{
            ...style,
            transform: transform,
          }}
        >
          <div
            style={{
              ...style,
              position: "absolute",
              zIndex: 2,
              left: 0,
              right: 0,
              top: isMobile ? "20%" : "25%",
              bottom: isMobile ? "20%" : "25%",
            }}
          >
            {children}
          </div>
          <EmbedVideo src={introVideo} isMobile={isMobile} />
        </div>
      )}
    </>
  )
}



Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "info",
    "success",
    "warning",
    "danger",
  ]),
  children: PropTypes.node,
  style: PropTypes.object,
  image: PropTypes.string,
  small: PropTypes.bool,
  doTransform: PropTypes.bool,
}
