import React from "react"
import LoanCalculator from "../../LoanCalculator"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// core components
import GridContainer from "../../Grid/GridContainer"
import GridItem from "../../Grid/GridItem"
// import CustomInput from "../../CustomInput/CustomInput"
// import Button from "../../CustomButtons/Button"
import Accordion from "../../Accordion/Accordion"

import workStyle from "../../../assets/jss/material-kit-pro-react/views/landingPageSections/workStyle"

class SectionFaq extends React.Component {
  render() {

    const { classes } = this.props
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <h2 className={classes.title}>Commonly Asked Questions</h2>
            <Accordion
              active={0}
              collapses={[
                {
                  title: "How much would my payment be?",
                  content: [
                    "In general, a boat payment should range between $6.50 - $7.50 per thousand dollars financed on a 20 year loan. That means that a $100,000 boat could cost you between $585 - $675 / month. This estimation assumes that the minimum 10% deposit is made on the boat bringing the total financed amount to $90,000. This example is based on a Tier One credit rating.",
                    <LoanCalculator key="loanCalc" />,
                  ],
                },
                {
                  title: "What is my actual payment and approval based on?",
                  content:
                    "Bank approvals are primarily based not just on your FICO score. Other parameters such as high credit, Debt to Income ratio, Employment Stability and your Loan to Vessel Value (how much money you are looking to borrow versus what the bank thinks the vessel is worth, regardless of what you intend to pay for it) round out the most important things the bank considers when loaning on a boat.",
                },
                {
                  title: "How fast can my loan be processed and funded?",
                  content:
                    "Loans can be closed as quickly as a couple days, however, there are many factors that determine the exact time frame including items like the vessel make, model and year as well as the applicant's credit and whether or not the vessel requires an inspection.",
                },
                {
                  title:
                    "Can my current boat be refinanced to improve my payments?",
                  content:
                    "YES! Boats can be refinanced to improve payments and / or re-power. The only thing to consider is that the total owed on the vessel has to be a minimum of $75,000 (Hull and Engines combined).",
                },
                {
                  title: "Can CCO help me with my purchase?",
                  content:
                    "YES! We have teamed up with finance industry veteran Jimmy Velez and created CCO Finance to do just that! We work with 21 banks and use the leverage of our network to get the best rates available in today's marketplace as well as find financing for deals that are less than perfect. We can help individuals directly or provide a financing division for existing brokers and dealers alike.",
                },
                {
                  title:
                    "Can CCO review my existing loan to see exactly what I'm paying for?",
                  content:
                    "YES! It is never too late to look over your deal. Often times we find that there are items on an existing loan that are cancelable that may actually save you money without refinancing and we would be happy to take a look and see how we can help at no charge.",
                },
                {
                  title: "What credit do I need to buy a boat?",
                  content:
                    "There are eight tiers of credit and most deals in the Recreational space including Boats, RV's and Airplanes lend to folks in the Tier One to Tier three range. This differs slightly from the auto market that loans all the way up Tier Five.",
                },
                {
                  title: "We're a dealer. How can CCO Finance help us?",
                  content:
                    "CCO Finance can act as your finance division and handle financing transactions from initiation to close. We also have excellent programs that helps get customers of most credit tier qualifications approved for their dream boat getting your deal closed and funded in as little as two to three days!",
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(workStyle)(SectionFaq)
