import React, { Component } from "react"
import PropTypes from "prop-types"
import DisplayChild from "./DisplayChild"
import { numberToCurrency } from "../../helpers/common"

class Display extends Component {
  state = {
    APR: 0.0499,
  }

  componentDidMount() {
    // this.calculateAPR();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props) {
  //     this.calculateAPR();
  //   }
  // }

  // calculateAPR = () => {
  //   const { amount } = this.props;
  //
  //   // if (1000 < amount && amount < 5000) {
  //   //   this.setState({ APR: 0.05 });
  //   // }
  //   // if (5000 < amount && amount < 10000) {
  //   //   this.setState({ APR: 0.1 });
  //   // }
  //   // if (10000 < amount && amount < 15000) {
  //   //   this.setState({ APR: 0.15 });
  //   // }
  //   // if (15000 < amount && amount < 20000) {
  //   //   this.setState({ APR: 0.2 });
  //   // }
  // };

  calculateMonthlyRepayment = () => {
    const { amount, years, down } = this.props
    const downPayment = amount * (down / 100)
    const amountBorrowed = amount - downPayment
    const monthlyRate = this.state.APR / 12
    const factor = Math.pow(monthlyRate + 1, years * 12)
    const numerator = monthlyRate * factor
    const denominator = factor - 1
    const quotient = numerator / denominator
    const payment = amountBorrowed * quotient
    // return payment.toFixed(2);
    return <h4>{numberToCurrency(payment, "$")}</h4>
  }

  percentageAPR = () => {
    return <h4>{this.state.APR * 100}%</h4>
  }

  downPayment = () => {
    const { amount, down } = this.props
    const downPayment = amount * (down / 100)
    return <h4>{numberToCurrency(downPayment, "$")}</h4>
  }

  amountBorrowed = () => {
    const { amount, down } = this.props
    const borrowed = amount * ((100 - down) * 0.01)
    return <h4>{numberToCurrency(borrowed, "$")}</h4>
  }

  render() {
    const { down } = this.props
    return (
      <div className="flex">
        <DisplayChild
          func={this.downPayment()}
          text={`Down Payment (${down}%)`}
        />
        <DisplayChild func={this.amountBorrowed()} text="Total Financed" />
        <DisplayChild
          func={this.calculateMonthlyRepayment()}
          text="Monthly Payment"
        />
      </div>
    )
  }
}

Display.propTypes = {
  years: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
}

export default Display
