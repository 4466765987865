import React from "react"
import useWindowDimensions from "../../hooks/useWindowDimensions"

const style = {
  container: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
}

export function EmbedVideo(props) {
  const { height, width } = useWindowDimensions()
  const { isMobile, src } = props
  const isAnamorphic = () => {
    return width / height <= 1.77
  }

  return (
    <div
      style={style.container}
      dangerouslySetInnerHTML={{
        __html: `
            <video
              loop
              muted
              autoplay
              playsInline
              controls="false"
              poster="${src + ".jpg"}"
              src="${src}"
              style="
                position: absolute;
                height: ${isAnamorphic() ? "100%" : "auto"};
                width: ${isAnamorphic() ? "auto" : "100%"};
                top: 0;
                left: ${isMobile ? "-105%" : "0"};
                z-index: -1;"
            />
        `,
      }}
    />
  )
}
