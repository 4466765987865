import React, { Component } from "react"
import InputRange from "react-input-range"
import Display from "./Display"
import { numberToCurrency } from "../../helpers/common"
import InquiryForm from "../AgileCRM/InquiryForm"
import Button from "../CustomButtons/Button"
import SweetAlert from "react-bootstrap-sweetalert"

import "./index.css"
import "react-input-range/lib/css/index.css"

class Calculator extends Component {
  _isMounted = false
  state = {
    amountValue: 100000,
    yearsValue: 20,
    downPaymentValue: 20,
    showContact: false,
    showAlert: false,
  }

  componentDidMount() {
    this._isMounted = true
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  handleAmountChange = value => {
    this._isMounted && this.setState({ amountValue: value })
  }
  handleYearChange = value => {
    this._isMounted && this.setState({ yearsValue: value })
  }
  handleDownPaymentChange = value => {
    this._isMounted && this.setState({ downPaymentValue: value })
  }

  success = () => {
    this._isMounted && this.setState({ showContact: false, showAlert: true })
  }

  message = () => {
    const { showAlert } = this.state
    return (
      <>
        {showAlert && (
          <SweetAlert
            // danger
            showCancel
            title="Finance Request Success!"
            customButtons={
              <React.Fragment>
                <Button
                  onClick={() => {
                    this._isMounted && this.setState({ showAlert: false })
                  }}
                >
                  Ok
                </Button>
              </React.Fragment>
            }
            onConfirm={() => {
              this._isMounted && this.setState({ showAlert: false })
            }}
            onCancel={() => {
              this._isMounted && this.setState({ showAlert: false })
            }}
          >
            Thank you for submitting a request for financing! We will be in
            contact with you very soon to get the process started! Thank you for
            trusting us with the opportunity to help you with your boating
            journey!
          </SweetAlert>
        )}
      </>
    )
  }

  render() {
    const {
      amountValue,
      yearsValue,
      downPaymentValue,
      showContact,
    } = this.state
    return (
      <div className="calculator">
        {this.message()}
        <div className="slider">
          <h4>
            The boat I'm interested in costs{" "}
            {numberToCurrency(amountValue, "$")}
          </h4>
          <InputRange
            step={1000}
            maxValue={1000000}
            minValue={25000}
            value={amountValue}
            onChange={this.handleAmountChange}
          />
        </div>
        <div className="slider">
          <h4>
            I want to pay over {yearsValue} year{yearsValue > 1 && "s"} (
            {yearsValue * 12} months)
          </h4>
          <InputRange
            step={5}
            maxValue={20}
            minValue={10}
            value={yearsValue}
            onChange={this.handleYearChange}
          />
        </div>
        <div className="slider">
          <h4>
            I want to put a {downPaymentValue}% (
            {numberToCurrency(amountValue * (downPaymentValue / 100), "$")})
            down payment
          </h4>
          <InputRange
            step={5}
            maxValue={20}
            minValue={10}
            value={downPaymentValue}
            onChange={this.handleDownPaymentChange}
          />
        </div>
        <Display
          years={yearsValue}
          amount={amountValue}
          down={downPaymentValue}
        />
        <div style={{ marginTop: "20px", maxWidth: "100%" }}>
          <Button
            color={"primary"}
            onClick={e => {
              e.preventDefault()
              this.setState({ showContact: !showContact })
            }}
          >
            Finance This Boat
          </Button>
          {showContact && (
            <InquiryForm deal={this.state} success={this.success}/>
          )}
        </div>
      </div>
    )
  }
}

export default Calculator
