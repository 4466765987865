import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// core components
import Footer from "../Footer/Footer.jsx"
import GridContainer from "../Grid/GridContainer.jsx"
import GridItem from "../Grid/GridItem.jsx"
import Parallax from "../Parallax/Parallax.jsx"

import landingPageStyle from "../../assets/jss/material-kit-pro-react/views/landingPageStyle"

// Sections for this page
import SectionProduct from "./Sections/SectionProduct"
// import SectionWork from "./Sections/SectionWork"
import SectionFaq from "./Sections/SectionFaq"

class FinancingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  }
  render() {
    const { classes, ...rest } = this.props
    return (
      <div style={{ paddingTop: "10px" }}>
        <Parallax
          image={require("../../assets/img/financing-bg2.jpg")}
          filter="dark"
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <h1 className={classes.title}>
                  Financing: Clear. Quick. Transparent.
                </h1>
                <h4 style={{fontWeight: 400}}>
                    We help buyers, private sellers, dealers and direct
                    manufacturers by providing industry leading financing
                    options and unparalleled service. See what others are saying
                    and how it works below.
                </h4>
                <br />
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionProduct />
            <SectionFaq />
          </div>
        </div>
        <Footer content={<div />} />
      </div>
    )
  }
}

export default withStyles(landingPageStyle)(FinancingPage)
